import styled from "styled-components";

export const Calc = styled.div`
  margin-top: -1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  overflow: visible;
  max-width: 100vw;
  .seperator {
    width: 95%;
    height: 1px;
    margin: 1rem 0;
    background-color: #b7c7d6;
  }
  h5 {
    padding: 0;
    font-size: 16px;
    margin: 0;
    margin-bottom: 2rem;
    /* justify-self: center; */
    text-align: center;
    color: #2e3841;
  }
  .error {
    width: 80vw;
    color: red;
    font-size: medium;
  }
`;

export const StyledButton = styled.button`
  margin: 1rem 0;
  padding: 0.5rem 5rem;
  background-color: #3195ed;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: large;
`;
export const StyledButtonA = styled.button`
  width: 90vw;
  height: 5vh;
  margin: 1rem 0;
  /* padding: 0.5rem 5rem; */
  background-color: #5d7b95;
  color: white;
  border: none;
  border-radius: 0.9rem;
  font-size: large;
`;
export const ButtonWrapper = styled.div`
  /* width: 50vw; */
  /* height: 12vh; */
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const IsotonyStyledButton = styled.button`
  width: 30vw;
  height: 4vh;
  /* padding: 0.5rem 5rem; */
  margin-bottom: 1rem;
  background-color: #3195ed;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-size: larger;
`;
export const Shake = styled.div`
  width: 90%;
  height: 100vh;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
`;
export const MainTitle = styled.h2`
  width: 80vw;
  /* height: 8vh; */
  display: flex;
  /* flex-direction: column; */
  text-align: center;
  align-items: center;
  justify-content: center;
`;
export const SecondTitles = styled.h3`
  width: 80vw;
  height: 4vh;
  display: flex;
  /* flex-direction: column; */
  text-align: center;
  align-items: center;
  justify-content: center;
`;
export const Explanation = styled.div`
  width: 90vw;
  height: 2vh;
  text-align: right;
  font-size: 1.5rem;
  font-weight: 700;
  /* margin-bottom: 0.5rem;
  margin-top: 0.7rem; */
`;
export const CalcExplanation = styled.div`
  width: 90vw;
  height: 2vh;
  text-align: right;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
`;
export const Ol = styled.ol`
  /* width:90vw;
height: 40vh; */
  /* margin-left: 2rem; */
  margin-right: 4vw;
  padding: 0;
`;
export const ExplanationDiv = styled.p`
  width: 90vw;
  height: 24vh;
  text-align: right;
`;
export const MakingOrder = styled.p`
  width: 90vw;
  height: 2vh;
  text-align: right;
  /* border-spacing:40px; */
`;
export const ImageWrapper = styled.div`
  width: 90vw;
  height: 35vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-right: 5vh; */
`;
export const TitlesWrapper = styled.div`
  width: 90vw;
  height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Results = styled.div`
  width: 90%;
  margin-right: 5%;
  display: flex;
  /* justify-content: center; */

  /* align-items: center; */
  flex-direction: column;
  margin-top: -2rem;
  h4 {
    align-self: center;
  }

  .asd {
    display: flex;
    align-items: center;

    svg {
      margin-left: 0.5rem;
      width: 2rem;
      height: 2rem;
    }
  }
  .note {
    margin: 0.5;
  }
`;

export const CheckBoxWrapper = styled.div`
  display: flex;
  width: 100vw;
  justify-content: flex-start;
  align-items: right;
`;

export const P = styled.p`
  /* color: black; */
  font-size: large;
  margin-right: 0.5rem;
`;
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90vw;
  height: 5vh;
  border: 1px black;
`;
export const Title = styled.div`
  width: 58vw;
  height: 5vh;
  /* justify-content: first baseline; */
  text-align: center;
  display: flex;
  align-items: center;
  /* margin-top: 3rem; */
  font-size: 1.2rem;
  font-weight: 700;
`;
export const IsotunyStyledButton = styled.div`
  /* margin: 1rem 0; */
  /* padding: 0.5rem 5rem; */
  background-color: #5d7b95;
  color: white;
  width: 30vw;
  height: 5.4vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: 0.5rem;
  font-size: 1rem;
`;
export const Table = styled.table`
  margin-bottom: 5rem;
`;

export const Th = styled.th`
  border: 1px solid black;
  background-color: #fcf6f6;
  font-size: smaller;
`;
export const Td = styled.th`
  border: 1px solid black;
  background-color: #fcf6f6;

  font-size: smaller;
`;
export const TableWrapper = styled.div`
  margin-bottom: 6vh;
  height: 16vh;
`;
export const CalculateError = styled.p`
  color: red;
  text-align: center;
`;
