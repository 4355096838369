import { useEffect, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
// import { Document, Page } from "@react-pdf/renderer";
import styled from "styled-components";
import { DefaultContainer } from "../../containers/DefaultContainer";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { useFile } from "../../hooks/useFile";
import { ReactComponent as StarBlack } from "../../assets/Svgs/starBlack.svg";
import { ReactComponent as StarRed } from "../../assets/Svgs/starRed.svg";
import { BASE_URL } from "../../api/constants";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import axios from "axios";
import { IconContext } from "react-icons";
import { AiFillStar } from "react-icons/ai";
import { AiOutlineStar } from "react-icons/ai";
interface IProps {
  file: string;
  setFile?: React.Dispatch<React.SetStateAction<string>>;
}

export const FileView = ({ file, setFile }: IProps) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const { setTreeFile } = useFile();
  const [favorite, setFavorite] = useState(false);
  const [showFavorite, setShowFavorite] = useState(false);
  const user = useRecoilValue(userInfo);
  const email = user.user.userPrincipalName;
  const { treeFile } = useFile();
  useEffect(() => {
    axios
      .post(
        `${BASE_URL}/likes/isFavorite`,
        { email: email, fileName: treeFile.filename },
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if (result.data) {
          setFavorite(result.data.likeStatus);
          setShowFavorite(true);
        }
      });
  }, []);
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
    // setPageNumber(numPages);
  }

  return (
    <Wrapper style={{ height: "100%", width: "96vw" }}>
      <DivFavoriteandReturn>
        <FileViewButton
          onClick={() => {
            setFile && setFile("");
            setTreeFile({
              fileSrc: undefined,
              fileType: undefined,
              filename: undefined,
            });
          }}
        >
          חזור
        </FileViewButton>
        <IconeHer
          onClick={() => {
            setFavorite((prev) => !prev);
            axios
              .post(
                `${BASE_URL}/likes/toggleFavorite`,
                { email: email, fileName: treeFile.filename },
                {
                  withCredentials: true,
                }
              )
              .then((result) => {
                if (result.data) {
                }
              });
          }}
        >
          {showFavorite ? (
            favorite ? (
              <IconContext.Provider
                value={{ color: "#ED8A19", size: "2.5rem" }}
              >
                <div>
                  <AiFillStar />
                </div>
              </IconContext.Provider>
            ) : (
              <IconContext.Provider value={{ size: "2.5rem" }}>
                <div>
                  <AiOutlineStar />
                </div>
              </IconContext.Provider>
            )
          ) : (
            <></>
          )}
        </IconeHer>
      </DivFavoriteandReturn>
      <StyleDocument
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={console.error}
        options={{ height: 300 }}
      >
        <Page height={800} width={350} pageNumber={pageNumber} />
      </StyleDocument>

      {numPages && (
        <PaginationWarper>
          <PaginationButton
            disabled={pageNumber === 1}
            onClick={() => setPageNumber(pageNumber - 1)}
          >
            <MdKeyboardArrowRight />
          </PaginationButton>

          <PagePos>
            עמוד {pageNumber} מתוך {numPages}
          </PagePos>
          <PaginationButton
            disabled={pageNumber === numPages}
            onClick={() => setPageNumber(pageNumber + 1)}
          >
            <MdKeyboardArrowLeft />
          </PaginationButton>
        </PaginationWarper>
      )}
    </Wrapper>
  );
};

const DivFavoriteandReturn = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const IconeHer = styled.div`
  height: 3rem;
  width: 2rem;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
`;

const FileViewButton = styled.button`
  margin: 1rem 0;
  padding: 0.5rem 2rem;
  max-width: 30vw;
  background-color: #3195ed;
  color: white;
  border: none;
  font-weight: 600;
  border-radius: 0.5rem;
  font-size: large;
`;

const Wrapper = styled.div`
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const StyleDocument = styled(Document)`
  display: flex;
  justify-content: center;

  .react-pdf__Page {
    display: flex;
    position: relative;
    max-width: 100vw;
    /* max-height: 100vh; */
    /* width: 100%; */
    /* height: 100%; */
    overflow: hidden;
    /* overflow: auto; */
  }
`;

const PagePos = styled.p`
  display: flex;
  justify-content: center;
`;
const PaginationWarper = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
const PaginationButton = styled.button`
  background: unset;
  display: flex;
  align-items: center;
  border: unset;
  border-radius: 100%;
  background: #8080804a;
  padding: 0.5rem;
  color: white;
  font-size: 20px;
  font-weight: 700;
`;
