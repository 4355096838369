import styled from "styled-components";
import { Header } from "../components/Header/Header";
import blueBg from "../assets/blueBackgroung.jpeg";
import greyBg from "../assets/waterSun.png";
import whiteBg from "../assets/pure-white-background-85a2a7fd.jpg";
import { BottomDefaultContainer } from "./BottomContainer";

interface IProps {
  children: React.ReactNode;
  background?: string;
  search?: boolean;
  plus?: boolean;
  overflow?: string;
  height?: number;
}

export const DefaultContainer = ({
  children,
  background,
  search,
  plus,
  overflow,
}: IProps) => {
  return (
    <AppWrapper background={background} overflow={overflow}>
      <Header plus={plus} />
      <Main>
        {children}
      </Main>
      <BottomDefaultContainer />
    </AppWrapper>
  );
};

const AppWrapper = styled.div`
background-image: ${(props: IProps) =>
  props.background === "blue"
  ? `url(${blueBg})`
  : props.background === "grey"
  ? `url(${greyBg})`
  : `url(${whiteBg})`};
  overflow: ${(props) => props.overflow};
  background-size: cover;
  position: absolute;
  bottom: 0;
  top: 0;
  height: ${(props) => (props?.height ? `${props.height}vh` : "100vh")};
  width: 100vw;
  overflow-x: hidden;
  justify-content: space-between;
  `;
  
const Main = styled.div`
  // overflow
`
