import axios from "axios";
import React, { Dispatch, useState } from "react";
import { BASE_URL } from "../../../api/constants";
import shareB from "../../../assets/Svgs/shareB.svg";
import shareY from "../../../assets/Svgs/shareY.svg";

interface IProps {
  setText: Dispatch<React.SetStateAction<string>>;
  filename: string;
  email: string;
  shortName: string;
}

function encodeFileName(filename: string): string {
  const parts = filename.split('/');
  
  const encodedParts = parts.map(part => {
    if (/[^\x00-\x7F]/.test(part)) {
      return encodeURIComponent(part);
    }
    return part;
  });
  
  return encodedParts.join('/');
}

function copyToClipboard(string: string) {
  let textarea;
  let result;

  try {
    textarea = document.createElement("textarea");
    textarea.setAttribute("readonly", "true");
    textarea.setAttribute("contenteditable", "true");
    textarea.style.position = "fixed";
    textarea.value = string;

    document.body.appendChild(textarea);

    textarea.focus();
    textarea.select();

    const range = document.createRange();
    range.selectNodeContents(textarea);

    const sel = window.getSelection();
    sel?.removeAllRanges();
    sel?.addRange(range);

    textarea.setSelectionRange(0, textarea.value.length);
    result = document.execCommand("copy");
  } catch (err) {
    console.error(err);
    result = null;
  } finally {
    if (textarea) document.body.removeChild(textarea);
  }

  if (!result) {
    const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
    result = prompt("אנא סמן והעתק את הקישור", string);
    if (!result) {
      return false;
    }
  }
  return true;
}

const fileShare = async (email: string, filename: string) => {
  
  const encodedFilename = encodeFileName(filename);
  
  return axios
    .get(`${BASE_URL}/tree/downloadFile/${encodedFilename}`, {
      withCredentials: true,
    })
    .then((res) => {
      axios.post(
        `${BASE_URL}/fileLogs/sendFileLog`,
        { email, action: "Share", fileName: filename },
        {
          withCredentials: true,
        }
      );
      
      
      let processedUrl = res.data;
      
      
      if (processedUrl.includes(' ')) {
        processedUrl = processedUrl.replace(/\s+/g, '%20');
      }
      
      try {
        const url = new URL(processedUrl);
        const pathParts = url.pathname.split('/');
        const encodedParts = pathParts.map(part => {
          if (/[^\x00-\x7F]/.test(part)) {
            return encodeURIComponent(decodeURIComponent(part));
          }
          return part;
        });
        url.pathname = encodedParts.join('/');
        processedUrl = url.toString();
      } catch (e) {
        console.error('Error processing URL:', e);
      }
      
      return processedUrl;
    })
    .catch((err) => {
      console.log(err);
      return false;
    });
};

export const FileShare = ({ setText, shortName, email, filename }: IProps) => {
  const [shareLogo, setShareLogo] = useState(shareB);

  const setTheName = () => {
    setText(shortName);
  };

  return (
    <button
      onTouchStart={() => setShareLogo(shareY)}
      onMouseDown={() => setShareLogo(shareY)}
      onMouseUp={() => setShareLogo(shareB)}
      onTouchEnd={() => setShareLogo(shareB)}
      onClick={() => {
        setText("טוען...");
        fileShare(email, filename).then((link: string) => {
          if (link) {
            copyToClipboard(link);
            setText("קישור לקובץ הועתק בהצלחה.");
            setTimeout(setTheName, 3000);
          } else {
            setText("שגיאה בהעתקת הקישור");
            setTimeout(setTheName, 3000);
          }
        });
      }}
    >
      <img className={"share"} src={shareLogo} alt={"שיתוף"} />
    </button>
  );
};
