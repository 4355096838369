import { ContentState, convertFromHTML, EditorState } from "draft-js";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import { convertToRaw, convertFromRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Wrapper } from "./styles";
import { editorLabels } from "./editorLabels";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

interface IProps {
  markup: string;
  setMarkUp: React.Dispatch<React.SetStateAction<string>>;
}

export const RichTextEditor = ({ markup, setMarkUp }: IProps) => {
  const [isKeboardOpen, setKeyboardOpen] = useState(false);
  window.addEventListener("resize", (event) => {
    // if current/available height ratio is small enough, virtual keyboard is probably visible

    setKeyboardOpen(window.innerHeight / window.screen.availHeight > 0.6);
  });
  const [optinos, setOptions] = useState([
    "inline",
    "blockType",
    "fontSize",
    "fontFamily",
    "list",
    "textAlign",
    "colorPicker",
    "link",
    "emoji",
    "image",
    "history",
  ]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  useEffect(() => {
    if (markup) {
      const blocksFromHTML = convertFromHTML(markup);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(state));
    }
  }, []);

  const rawContentState = convertToRaw(editorState.getCurrentContent());
  useEffect(() => {
    console.log(convertToRaw(editorState.getCurrentContent()));
    const markup = draftToHtml(rawContentState);
    setMarkUp(markup);
  }, [editorState]);

  useEffect(() => {
    if (isKeboardOpen) {
      setOptions([]);
    } else {
      setOptions([
        "inline",
        "blockType",
        "fontSize",
        "fontFamily",
        "list",
        "textAlign",
        "colorPicker",
        "link",
        "emoji",
        "image",
        "history",
      ]);
    }
  }, [isKeboardOpen]);

  return (
    <Wrapper>
      <Editor
        toolbar={{
          options: optinos,
          inline: {
            inDropdown: false,
            options: ["bold", "underline"],
          },
          list: { inDropdown: true },
          textAlign: { inDropdown: false },
          link: { inDropdown: true },
          history: { inDropdown: false },
          image: {
            uploadEnabled: true,
          },
          emoji: undefined,
        }}
        localization={{ locale: "he", translations: editorLabels }}
        editorState={editorState}
        onEditorStateChange={setEditorState}
      />
    </Wrapper>
  );
};
