const isLocal = typeof window != "undefined" && window.location.href.includes("localhost");
const isDev = !isLocal && window.location.href.includes("safetydev");
// const isLocal = true;
require("dotenv").config({ path: "./.env" });

export const BASE_URL = isLocal
  ? "http://localhost:3001/api"
  : isDev
  ? "https://safetydev.azurewebsites.net/api"
  : "https://safety.idf.il/api";
