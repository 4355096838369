export const CARDS = [
  "הידע בבטיחות",
  "פיזיולוגי",
  "הכשרות וקורסים",
  'ענ"א',
  "ניהול הבטיחות",
  "בסיס חכם",
  "אינדקס שריפות"
];
export const SMART_BASE_URL = "https://www.base.idf.il/";
export const ACADEMY_COURSES_URL =
  "https://academy.digital.idf.il/courses?categories=81";
export const Fires ="https://ims.gov.il/he/Fires"