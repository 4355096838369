import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CalcInput } from "../../../CalcInput";
import { ImageSelect } from "../../../ImageSelect";
import Imageeee from "../../../../assets/Screenshot_2.png";
import IsotonyImage from "../../../../assets/IsotonyImage.png";
import { ReactComponent as Temp1 } from "../../../../assets/Svgs/Physiological/Calculator/Temp1.svg";
import { ReactComponent as Temp2 } from "../../../../assets/Svgs/Physiological/Calculator/Temp2.svg";
import { ReactComponent as Temp3 } from "../../../../assets/Svgs/Physiological/Calculator/Temp3.svg";
import { ReactComponent as Temp4 } from "../../../../assets/Svgs/Physiological/Calculator/Temp4.svg";
import { ReactComponent as Sort } from "../../../../assets/Svgs/Physiological/Calculator/sort.svg";
import { ReactComponent as Exercise } from "../../../../assets/Svgs/Physiological/Calculator/exercise.svg";
import { ReactComponent as Effort1 } from "../../../../assets/Svgs/Physiological/Calculator/running.svg";
import { ReactComponent as Effort2 } from "../../../../assets/Svgs/Physiological/Calculator/manInHike.svg";
import { ReactComponent as Effort3 } from "../../../../assets/Svgs/Physiological/Calculator/shoot.svg";
import { ReactComponent as Effort4 } from "../../../../assets/Svgs/Physiological/Calculator/education.svg";
import { ReactComponent as effortExtremlyHard } from "../../../../assets/Svgs/Physiological/Calculator/timeManagement.svg";
import { ReactComponent as KestrelHeat } from "../../../../assets/Svgs/Physiological/Calculator/KestrelHeat.svg";
import { Option } from "../../../ImageOption";
// import {isotoni} from "../../../../assets/fileIcons/ioton"
import { between } from "../utils/validation";
import { CgCloseO } from "react-icons/cg";
import { BiCheckCircle } from "react-icons/bi";
import { fixNumber } from "../utils/fixValue";
import { ToggleSwitch } from "../../../Switch";
import { CheckBox } from "../../../CheckBox";
import {
  ButtonWrapper,
  Calc,
  CalcExplanation,
  CalculateError,
  CheckBoxWrapper,
  Explanation,
  ExplanationDiv,
  ImageWrapper,
  IsotonyStyledButton,
  IsotunyStyledButton,
  MainTitle,
  MakingOrder,
  Ol,
  P,
  Results,
  SecondTitles,
  Shake,
  StyledButton,
  StyledButtonA,
  Table,
  TableWrapper,
  Td,
  Th,
  // Table,
  Title,
  // Titles,
  TitlesWrapper,
  TitleWrapper,
} from "./styles";
import { createNamedExports } from "typescript";
import { CalcProps } from "../..";
import { NewToolTip } from "../../../NewToolTip";

export const LiquidCalc = ({ setShouldShow, setToolTipMessage }: CalcProps) => {
  const values = ["מחשבון איזוטוני", "חישוב תצרוכת מים לפעילות"];
  const [selected, setSelected] = useState("חישוב תצרוכת מים לפעילות");

  const [relativeHumidity, setRelativeHumidity] = useState("");
  const [weight, setWeight] = useState("70");
  const [duration, setDuration] = useState("");
  const [cloudOrNight, setCloudOrNight] = useState(false);
  const [cloudOrNightString, setCloudOrNightString] = useState("יומית");
  const [temp, setTemp] = useState("");

  const [uncomfterableUnits, setUncomfterableUnits] = useState("");

  const [heatLevel, setHeatLevel] = useState("");

  const [activity, setActivity] = useState("אימון");
  const [effort, setEffort] = useState("ללא מאמץ (0)");
  const [error, setError] = useState("");
  const [isTakingPlace, setIsTakingPlace] = useState("");
  const [litersNum, setLitersNum] = useState("");
  const [limitations, setLimitations] = useState("");
  const [liquidsReturn, setLiquidReturn] = useState("");
  const [isResults, setIsResults] = useState(false);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isShake, setIsShake] = useState(false);
  const [openTable, setOpenTable] = useState(false);
  const [salt, setSalt] = useState("");
  const [saltInSpoon, setSaltInSpoon] = useState("");
  const [juice, setJuice] = useState("");
  const [juiceInCup, SetJuiceInCup] = useState("");
  const [calculateError, SetCalculateError] = useState("");
  const [isotonyError, SetIsotonyError] = useState(false);

  const CalculateTable = () => {
    if (litersNum == "1") {
      setSalt("1.25");
      setSaltInSpoon("רבע כפית");
      setJuice("70");
      SetJuiceInCup("0.4");
    } else if (litersNum == "5") {
      setSalt("6.25");
      setSaltInSpoon("כפית ורבע");
      setJuice("350");
      SetJuiceInCup("1.9");
    } else if (litersNum == "10") {
      setSalt("12.5");
      setSaltInSpoon("0.8 כפות");
      setJuice("700");
      SetJuiceInCup("3.9");
    } else if (litersNum == "20") {
      setSalt("25");
      setSaltInSpoon("1.7 כפות שטוחות");
      setJuice("1400");
      SetJuiceInCup("7.8");
    } else if (litersNum == "40") {
      setSalt("50");
      setSaltInSpoon("3.3 כפות שטוחות");
      setJuice("2800");
      SetJuiceInCup("15.6");
    }
  };
  useEffect(() => {
    if (cloudOrNight) {
      setCloudOrNightString("לילית");
    } else {
      setCloudOrNightString("יומית");
    }
  }, [cloudOrNight]);

  const clearForm = () => {
    setTemp("");
    setWeight("");
    setUncomfterableUnits("");
    setHeatLevel("");
    setActivity("אימון");
    setEffort("ללא מאמץ (0)");
    setDuration("");
  };

  const validateFields = () => {
    if (!effort) {
      setError("* יש לבחור רמת מאמץ");
    } else if (
      !heatLevel &&
      !uncomfterableUnits &&
      !(temp && relativeHumidity)
    ) {
      setError('* יש למלא לחות יחסית וטמפרטורה / ערך יא"נ / דרגת עומס חום.');
    } else if (!duration) {
      setError("* יש למלא את משך הפעילות.");
    } else {
      setError("");
      return true;
    }
    return false;
  };

  const validateFieldsValues = () => {
    if (relativeHumidity) {
      if (!Number(relativeHumidity)) {
        setError("הלחות היחסית שהוכנסה אינה ערך מספרי.");
        return false;
      } else if (!between(Number(relativeHumidity), 0, 100)) {
        setError("הלחות היחסית שהוכנסה איננה בטווח הרשום.");
        return false;
      }
    }

    if (temp) {
      if (!Number(temp)) {
        setError("הטמפרטורה שהוכנסה אינה ערך מספרי.");
        return false;
      } else if (!between(Number(temp), -50, 60)) {
        setError("הטמפרטורה שהוכנסה איננה בטווח הרשום.");
        return false;
      }
    }

    if (uncomfterableUnits) {
      if (!Number(uncomfterableUnits)) {
        setError("ערך אי הנוחות שהוכנס אינו ערך מספרי.");
        return false;
      } else if (!between(Number(uncomfterableUnits), 10, 50)) {
        setError("ערך אי הנוחות שהוכנס איננו בטווח הרשום.");
        return false;
      }
    }

    if (weight) {
      if (!Number(weight)) {
        setError("המשקל שהוכנס אינו ערך מספרי.");
        return false;
      } else if (!between(Number(weight), 40, 130)) {
        setError("המשקל שהוכנס איננו בטווח הרשום.");
        return false;
      }
    }

    if (duration) {
      if (!Number(duration)) {
        setError("משך הפעילות שהוכנס אינו ערך מספרי.");
        return false;
      } else if (!between(Number(duration), 1, 12)) {
        setError("משך הפעילות שהוכנס איננו בטווח הרשום.");
        return false;
      }
    }

    return true;
  };
  const HandleShakeClick = () => {
    setIsShake(true);
    setIsFirstPage(false);
  };
  const HandleResultsClick = () => {
    setIsShake(false);
    setIsFirstPage(true);
    setIsResults(false);
    clearForm();
  };

  const calculate = () => {
    let realLiquidReturn = (liquids as any)[effort][heatLevel];
    if (Number(realLiquidReturn)) {
      setIsTakingPlace("ניתן לבצע פעילות");
      realLiquidReturn = Number(realLiquidReturn);
      realLiquidReturn =
        realLiquidReturn + 0.01 * realLiquidReturn * (Number(weight) - 70);
      if (cloudOrNight) {
        realLiquidReturn -= 250;
      }
    } else {
      setIsTakingPlace("לא ניתן לבצע פעילות");
    }
    if (realLiquidReturn < 100) {
      setLiquidReturn("100");
    } else if (realLiquidReturn > 1500) {
      setLiquidReturn("1500");
    } else {
      setLiquidReturn(realLiquidReturn);
    }

    setIsResults(true);
  };

  const handleCalc = () => {
    if (validateFields() && validateFieldsValues()) {
      setIsFirstPage(false);
      calculate();
    }
  };
  const handleLitersCalc = () => {
    if (
      litersNum != "1" &&
      litersNum != "5" &&
      litersNum != "10" &&
      litersNum != "20" &&
      litersNum != "40"
    ) {
      console.log("false");
      console.log(litersNum);
      SetIsotonyError(true);
      setOpenTable(false);
      SetCalculateError("אנא בחר מספר מהרשימה");
    } else {
      console.log("true");
      setOpenTable(true);
      CalculateTable();
      SetIsotonyError(false);
    }
  };

  return (
    <>
      {isResults && (
        <Results>
          <h4>{`תוצאה עבור חישוב נוזלים ומלחים`}</h4>
          <p className="asd">
            {isTakingPlace === "ניתן לבצע פעילות" ? (
              <BiCheckCircle color="green" />
            ) : (
              <CgCloseO style={{ width: "5rem", height: "2rem" }} color="red" />
            )}
            <b>{`${isTakingPlace} ברמה: ${effort}`}</b>
          </p>

          {isTakingPlace === "ניתן לבצע פעילות" && (
            <>
              <p>
                {`ניתן לבצע פעילות ברמת `}
                <b>{effort}</b>
                {` בעומס `}
                <b>{heatLevel}</b>
                {` למשך `}
                <b>{duration} שעות </b>
                {` בפעילות `}
                <b>{cloudOrNightString}.</b>
                <br></br>
                {`  עבור אדם השוקל `}
                <b>{`${weight}`}</b>
                {` יש לשתות `}
                <b>
                  {" "}
                  {liquidsReturn > "1000"
                    ? `${(Number(liquidsReturn) / 1000).toFixed(
                        2
                      )} ליטר מים לשעה`
                    : `${liquidsReturn} מ"ל `}
                </b>
                {` לשעת פעילות ו- `}
                <b>
                  {" "}
                  {`${
                    Math.round(
                      (Number(liquidsReturn) / 1000) * Number(duration) * 2
                    ) / 2
                  } ליטר`}
                </b>
                {` לכל הפעילות. `}
              </p>

              <p
                className={"note"}
              >{`בכל מקרה, אין לשתות יותר מ 1.5 ליטר בשעה!!!`}</p>
              <p
                className={"note"}
              >{`בנוסף להחזר הנוזלים, יש לצרוך כל שעתיים מזון עשיר בפחמימות ובשעה השישית להוסיף לפחמימות   גם חלבון ומלחים.`}</p>
            </>
          )}
          <a
            href="https://safetydevstorage.blob.core.windows.net/files/טבלת%20התניות%20לפעילות%20חום.pdf?sp=r&st=2021-09-13T23:17:43Z&se=2031-09-14T09:17:43Z&spr=https&sv=2020-08-04&sr=c&sig=lk2kZQXFFHC3lf90jDS5AOqmU5ZPDsd5ile2Anx0Qp0%3D"
            target="_blank"
          >
            {" "}
            <StyledButtonA>
              טבלת דרגות מאמצים והחזר נוזלים בפעילות
            </StyledButtonA>
          </a>

          <StyledButton onClick={HandleResultsClick}>
            {"לחישוב מחדש"}
          </StyledButton>
        </Results>
      )}
      {!isResults && (
        <Calc>
          <ToggleSwitch
            values={values}
            selected={selected}
            width={"11rem"}
            fontsize="14px"
            setSelected={setSelected}
          />
        </Calc>
      )}

      {selected === "חישוב תצרוכת מים לפעילות" && !isResults && (
        <Calc>
          {/* <TitleWrapper>
            <Title>חישוב תצרוכת מים לפעילות</Title>
            <IsotunyStyledButton onClick={HandleShakeClick}>
              <P>מחשבון איזוטוני</P>
              <img src={IsotonyImage} width="60" height="50" alt="" title="" />
            </IsotunyStyledButton>
          </TitleWrapper>

          <br />
          <br /> */}
          <br></br>

          <CalcInput
            placeholder="לדוגמה - 2"
            value={duration}
            setValue={setDuration}
            topText={`משך הפעילות (שעות)`}
            bottomText={"טווח: 1 עד 12"}
            disabled={false}
          />
          <CalcInput
            placeholder="70"
            value={weight}
            setValue={setWeight}
            topText={`משקל (ק"ג)`}
            bottomText={"ניתן להזין משקל ספציפי"}
            disabled={false}
          />
          <ImageSelect
            title={"דרגת עומס חום"}
            options={tempOptions}
            setSelected={setHeatLevel}
            selected={heatLevel}
            disabled={false}
            setShouldShow={setShouldShow}
            setToolTipMessage={setToolTipMessage}
          />
          <br />
          <ImageSelect
            title={`רמת מאמץ (0-${activity == "מיון פיזי" ? "3" : "4"})`}
            options={
              activity == "מיון פיזי"
                ? effortOptions.filter((el) => el.text != "מאמץ עצים (4)")
                : effortOptions
            }
            setSelected={setEffort}
            selected={effort}
            isExam={activity == "מיון פיזי" ? true : false}
            setShouldShow={setShouldShow}
            setToolTipMessage={setToolTipMessage}
          />
          <br />

          <NewToolTip
            text={
              'בפעילות המבוצעות בלילה או ביום מעונן מתבצעת התאמה לכמות השתייה (מופחתת ב-250 מ"ל/לשעה)'
            }
            top={1.8}
            right={40}
            setShouldShow={setShouldShow}
            setToolTipMessage={setToolTipMessage}
          >
            <CheckBoxWrapper>
              <CheckBox
                text={"עננים / לילה"}
                clicked={cloudOrNight}
                setClicked={setCloudOrNight}
              />
            </CheckBoxWrapper>
          </NewToolTip>

          {error && <p className="error">{error}</p>}
          <StyledButton onClick={handleCalc}>{"חישוב"}</StyledButton>
        </Calc>
      )}
      {selected === "מחשבון איזוטוני" && !isResults && (
        <>
          <Shake>
            <TitlesWrapper>
              <MainTitle>משקה איזוטוני</MainTitle>
              <SecondTitles>הנחיות הכנה ומחשבון כמויות</SecondTitles>
            </TitlesWrapper>
            <Explanation>הסבר:</Explanation>
            <ExplanationDiv>
              במהלך פעילות גופנית ממושכת (מעל שעתיים רצופות) ישנו שימוש מוגבר
              בפחמימות במקביל לאובדן מוגבר של נוזלים ומלחים בזיעה. השלמתם במהלך
              פעילות הינה הכרחית לשימור הבריאות והתפקוד של החייל סביב הפעילות.
              משקאות איזוטונים מספקים במוצר אחד: נוזלים, מלחים ופחמימות כהשלמה
              למזון הרגיל המסופק בפעילות ממושכת. יש להגיש את המשקה האיזוטוני
              צונן (משקה איזוטוני חם פחות נעים לשתייה וחיילים לא יצרכו אותו)
            </ExplanationDiv>
            <Explanation>הנחיות ההכנה:</Explanation>
            {/* <MakingOrder>
            בוחרים את גודל המיכל ומוסיפים את הרכיבים על פי הסדר ההכנה ההנחיות
            הבא:
          </MakingOrder> */}
            <Ol>
              <li>מזינים בטבלה מטה את גודל מיכל השתיה.</li>
              <li>מוסיפים למיכל השתיה את כמות המלח המופיע בטבלה מטה.</li>
              <li>
                מוסיפים למיכל השתיה חצי ליטר מים חמים. מערבבים כדקה וחצי עד המסה
                מלאה של המלח ומוודאים שלא נשארו משקעים בתחתית המיכל.
              </li>
              <li>
                מוסיפים למיכל השתיה תרכיז מיץ ממותק רגיל (לא דיאט) על פי הכמות
                המופיע בטבלה ומערבבים.
              </li>
              <li>
                מוסיפים קרח עד כיסויי מלא של הנוזל במיכל השתיה ומערבבים (בקיץ,
                רצוי למלא יותר קרח בכדי לקבל משקה צונן).
              </li>
              <li>מוסיפים מים פושרים עד קצה מיכל השתיה.</li>
            </Ol>
            <CalcExplanation>מחשבון הכנת משקה איזוטוני:</CalcExplanation>
            <ButtonWrapper>
              <CalcInput
                placeholder="0"
                value={litersNum}
                setValue={setLitersNum}
                topText={`גודל מיכל (ליטרים)`}
                bottomText={`בחר:  1 /5 / 10/ 20 / 40`}
                disabled={false}
              />
            </ButtonWrapper>
            {isotonyError && <CalculateError>{calculateError}</CalculateError>}
            {openTable && (
              <TableWrapper>
                <Table>
                  <tr>
                    <Th>מלח (גר')</Th>
                    <Th>כמות מלח בכפית/ כף אכילה (5/15 גר')</Th>
                    <Th>תרכיז מיץ ממותק (מ"ל)</Th>
                    <Th>כמות תרכיז בכוס חד פעמית (180 מ"ל)</Th>
                    <Th>קרח</Th>
                    <Th>מ"ל מים</Th>
                  </tr>
                  <tr>
                    <Td>{salt}</Td>
                    <Td>{saltInSpoon}</Td>
                    <Td>{juice}</Td>
                    <Td>{juiceInCup}</Td>
                    <Td>הוספת קרח</Td>
                    <Td>הוספת מים עד מילוי המיכל</Td>
                  </tr>
                </Table>
              </TableWrapper>
            )}
            <ButtonWrapper>
              <IsotonyStyledButton onClick={handleLitersCalc}>
                {"חישוב"}
              </IsotonyStyledButton>
            </ButtonWrapper>
          </Shake>
        </>
      )}
    </>
  );
};

const tempOptions: Option[] = [
  {
    Img: Temp4,
    text: "ללא עומס (0)",
    textt: "0-22.0",
    isSelected: false,
    color: "#b8b8b8",
  },
  {
    Img: Temp3,
    text: "קל (1)",
    textt: "22.1-24.0",
    isSelected: false,
    color: "#2bcf4b",
  },
  {
    Img: Temp2,
    text: "מתון (2)",
    textt: "24.1-26.0",
    isSelected: false,
    color: "#fdff76",
  },
  {
    Img: Temp1,
    text: "בינוני (3)",
    textt: "26.1-28.0",
    isSelected: false,
    color: "#ff9215",
  },
  {
    Img: Temp1,
    text: "כבד (4)",
    textt: "28.1-30.0",
    isSelected: false,
    color: "#ff0404",
  },
  {
    Img: Temp1,
    text: "קיצוני (5)",
    textt: "מעל 30.1",
    isSelected: false,
    color: "#0a0808",
  },
];

const effortOptions: Option[] = [
  {
    Img: Effort4,
    text: "ללא מאמץ (0)",
    explanationExam: "תרגיל חשיבה במנוחה",
    explanation: `- פעילות תאורטית בכיתה \\ מקום מוצל 
    - הליכה עד למרחק 500 מ' ליום.`,
    isSelected: false,
    color: "#365ABA",
  },
  {
    Img: Effort3,
    text: "מאמץ קל (1)",
    isSelected: false,
    explanationExam: `- הליכה עד למרחק של 500 מטר רצוף ובקצב של עד 4 קמ"ש (כ-8 דקות ל-500 מטר הליכה), ולא מעל 2 ק"מ ביממה, על בגדי ב\' או בגדי ספורט ללא משקל נוסף.\n\
    - תרגילים טכניים הכוללים מאמץ גופני קל כגון פירוק והרכבת נשק.`,
    explanation:
      "- מטווחים נייחים\n- הליכה מעל 500 מ' ולא יותר מ- 2000 מ' ליום (לרבות אפוד ונשק)\n- תרגול טכני (פרוק והרכבת נשק קל, קשר, ע\"ר וכד'\n- אימונים ע\"ג רכב ורק\"מ (ללא פעילות רגלית)",
    color: "#365ABA",
  },
  {
    Img: Effort2,
    text: "מאמץ בינוני (2)",
    isSelected: false,
    explanationExam:
      '- תרגול נשיאת תד"ל, מנשא ואלונקה\n\
        - תרגולים טכניים הכוללים מאמץ גופני: חפירות, הקמת מאהל, פק"לי כוח וכדו\'.',
    explanation:
      '- תרגילים\\אימונים רגליים\n\
    - מסע רגיל\\תנועה רגלית (עד 6 קמ"ש במישור עם אפוד, נשק ואגמ"ק) עד 30 ק"מ משוקלל\n\
    - ניווטים\n\
    - מטווחים בתנועה או לאחר מאמץ\n\
    - אימוני כוח וקרב מגע בסיסי\n\
    - ריצה קצב קל',
    color: "#365ABA",
  },
  {
    Img: Effort1,
    text: "מאמץ קשה (3)",
    isSelected: false,
    explanationExam:
      "מסע, ריצה, בוחן כש\"ג, זחילות, שקים, סוציומטרי אלונקה ונשיאת משקל, הליכה עם קורה, הליכה עם אוהל וכדו'.",
    explanation:
      '- תרגילים\\אימונים רגליים\n\
    - תנועה רגלית בתנאי שטח קשים\n\
    - מסע רגיל\\פק"לים ותנועה רגלית מעל 30 ק"מ משוקלל\n\
    - מסע מזורז (מעל 6 קמ"ש)\n\
    - מסע אלונקות\n\
    - קרב מגע מתקדם\n\
    - ריצות',
    color: "#365ABA",
  },
  {
    Img: effortExtremlyHard,
    text: "מאמץ עצים (4)",
    isSelected: false,
    explanation: 'פעילויות במאמץ עצים ע"פ הוראת קחצ"ר בנושא כושר קרבי - מס\' 4',
    color: "#365ABA",
  },
];

const liquids = {
  "ללא מאמץ (0)": {
    "ללא עומס (0)": 50,
    "קל (1)": 100,
    "מתון (2)": 100,
    "בינוני (3)": 100,
    "כבד (4)": 200,
    "קיצוני (5)": 200,
  },
  "מאמץ קל (1)": {
    "ללא עומס (0)": 250,
    "קל (1)": 500,
    "מתון (2)": 750,
    "בינוני (3)": 750,
    "כבד (4)": 750,
    "קיצוני (5)": 1000,
  },
  "מאמץ בינוני (2)": {
    "ללא עומס (0)": 500,
    "קל (1)": 750,
    "מתון (2)": 1000,
    "בינוני (3)": 1000,
    "כבד (4)": "לא ניתן לבצע פעילות",
    "קיצוני (5)": "לא ניתן לבצע פעילות",
  },
  "מאמץ קשה (3)": {
    "ללא עומס (0)": 750,
    "קל (1)": 1000,
    "מתון (2)": 1250,
    "בינוני (3)": 1250,
    "כבד (4)": "לא ניתן לבצע פעילות",
    "קיצוני (5)": "לא ניתן לבצע פעילות",
  },
  "מאמץ עצים (4)": {
    "ללא עומס (0)": 750,
    "קל (1)": 1000,
    "מתון (2)": 1250,
    "בינוני (3)": 1250,
    "כבד (4)": "לא ניתן לבצע פעילות",
    "קיצוני (5)": "לא ניתן לבצע פעילות",
  },
};
