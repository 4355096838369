import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Card } from "../../atoms/Card";
import {
  ACADEMY_COURSES_URL,
  CARDS,
  Fires,
  SMART_BASE_URL,
} from "../../constants";
import { DefaultContainer } from "../../containers/DefaultContainer";
import usePermissions from "../../hooks/usePermissions";
import { ButtonWithIcon } from "../ButtonWithIcon";
import { Notification } from "../Notification";
import { PersonalFiles } from "./PersonalFiles";
import axios from "axios";
import { BASE_URL } from "../../api/constants";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import { Updates } from "./Updates";
import { Markup } from "interweave";
import { Popup } from "./Popup";
import safteySchedule from "../../assets/Svgs/safteySchedule.svg";
import warImg from "../../assets/warImg.png";
import reportImg from "../../assets/newReport.jpg";
import { HomeModal } from "./HomeModal";

const CURRENT_APP_VERSION = "1.0.0";

export const Home = () => {
  const history = useHistory();
  const { isAdminUser } = usePermissions();
  const user = useRecoilValue(userInfo);
  const email = user.user.userPrincipalName;
  const [markup, setMarkup] = useState("");
  const isNewUser = useCallback(() => {
    setTimeout(() => {
      axios.get(`${BASE_URL}/auth/isNewUser`, {
        withCredentials: true,
      });
      axios.post(
        `${BASE_URL}/userLogs/sendUserLog`,
        { email },
        {
          withCredentials: true,
        }
      );
    }, 1000);
  }, []);

  useEffect(() => {
    isNewUser();
  }, [isNewUser]);

  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    const lastAppVersion = window.localStorage.getItem("lastAppVersion");

    if (lastAppVersion !== CURRENT_APP_VERSION) {
      window.localStorage.setItem("lastAppVersion", CURRENT_APP_VERSION);
      setShouldShow(true);
    } else {
      setShouldShow(false);
    }
  }, []);

  return (
    <DefaultContainer background={true} search={true}>
      <HomeWrapper>
        <HomeModal shouldShow={shouldShow} setShouldShow={setShouldShow}>
          {""}
        </HomeModal>
       
        <ReportsWrapper>
        <Awar href="/warFiles"
              onClick={() => {
              axios.post(
                `${BASE_URL}/areaLogs/addAreaLog`,
                { email, name: "חרבות ברזל" },
                {
                  withCredentials: true,
                }
              );
            }}>
          <Wardiv>
          <WarImg src={warImg} />
            <Div>חרבות ברזל</Div>
            <WarImg src={warImg} />
          </Wardiv>
          </Awar>
        </ReportsWrapper>
        
        <ReportsWrapper>
          <Reportsdiv>
            <A href="/safetyreports"> דיווח ראשוני לאירוע בטיחות</A>
            <Div />
            <Img src={safteySchedule} />
          </Reportsdiv>
        </ReportsWrapper>
      
        {/* <SumPdf /> */}
        {/* <Notification
          text={"כאן תהיה הודעה כאן תהיה הודעה"}
          date={"17.05.21"}
          iconName={"car"}
        ></Notification> */}
        <CardsWrapper>
          <CardsColWrapper>
            <Card
              isActive={true}
              text={CARDS[0]}
              color="#0095CD"
              bgColor="#EDF7FE"
              onClickFunc={() => {
                history.push("/files");
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[0] },
                  {
                    withCredentials: true,
                  }
                );
              }}
            />
            <Card
              isActive={true}
              text={CARDS[1]}
              color="#FF6DE5"
              bgColor="#FEEDFB"
              onClickFunc={() => {
                history.push("/physiological");
                // axios.post(
                //   `${BASE_URL}/areaLogs/addAreaLog`,
                //   { email, name: CARDS[1] },
                //   {
                //     withCredentials: true,
                //   }
                // );
              }}
            />
            {/* <Card
              isActive={true}
              text={CARDS[6]}
              color="#cda523"
              bgColor="#E7FCEA"
              textSize={14}
              onClickFunc={() => {
                window.location.replace(Fires);
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[6] },
                  {
                    withCredentials: true,
                  }
                );
              }}
            /> */}
          </CardsColWrapper>
          <CardsColWrapper>
            {/* <Card
              isActive={false}
              text={CARDS[3]}
              color="#936EFF"
              bgColor="#F1EDFE"
            /> */}
            {/* <Card
              isActive={false}
              text={CARDS[4]}
              color="#FFA368"
              bgColor="#FBF0E9"
            /> */}
            <Card
              isActive={true}
              text={CARDS[5]}
              color="#4A74FF"
              bgColor="#EDF1FE"
              onClickFunc={() => {
                history.push("/smartBasePage");
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[5] },
                  {
                    withCredentials: true,
                  }
                );
              }}
            />
            {/* <Card
              isActive={true}
              text={CARDS[2]}
              color="#23CD52"
              bgColor="#E7FCEA"
              textSize={14}
              onClickFunc={() => {
                window.location.replace(ACADEMY_COURSES_URL);
                axios.post(
                  `${BASE_URL}/areaLogs/addAreaLog`,
                  { email, name: CARDS[2] },
                  {
                    withCredentials: true,
                  }
                );
              }}
            /> */}
          </CardsColWrapper>
        </CardsWrapper>
        <ButtonsWrapper>
          {/* <ButtonWithIcon
            text={"טפסים"}
            onClickFunc={() => {
              history.push("/documents");
            }}
          />
          <ButtonWithIcon
            text={"שאלות ותשובות"}
            onClickFunc={() => {
              history.push("/Q&A");
            }}
          /> */}
        </ButtonsWrapper>
        {markup && (
          <Popup setMarkup={setMarkup}>
            <Markup content={markup} />
          </Popup>
        )}

        <Updates setMarkup={setMarkup} />
      </HomeWrapper>
    </DefaultContainer>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Reportsdiv = styled.div`
  width: 90vw;
  background-color: white;
  border-radius: 3vw;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.1rem;
  height: 5vh;
`;
const Wardiv = styled.div`
  width: 60vw;
  background-color: #000000;
  border-radius: 3vw;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: 500;
  font-size: 1.5rem;
  height: 5vh;
`;
const A = styled.a`
  color: #0a0909;
  text-decoration: none;
  font-weight:900;
`;
const Awar = styled.a`
    text-decoration: none;
   

`;
const ReportsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4vw;
`;

const HomeWrapper = styled.div`
  /* min-width: 100vw;
  min-height: 100%; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0.1rem;
`;

const CardsWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const CardsColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
const Img = styled.img`
  width: 5vw;
  height: 5vh;
`;
const WarImg = styled.img`
  width: 10vw;
  height: 5vh;
`;
const Div = styled.div`
 color: #c92a2a;
  text-decoration: none;
  font-weight:900;
  `;
