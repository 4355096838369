import React, { useState } from "react";
import styled from "styled-components";
import { DefaultContainer } from "../../containers/DefaultContainer";
import PhysiologicalSvg from "../../assets/Svgs/Physiological/physiologicalWheel.svg";
import wheelCenter from "../../assets/Svgs/Physiological/wheelCenter.svg";
import { ReactComponent as LiquidSvg } from "../../assets/Svgs/Physiological/liquid.svg";
import { ReactComponent as ColdnessSvg } from "../../assets/Svgs/Physiological/coldness.svg";
import { ReactComponent as HeatSvg } from "../../assets/Svgs/Physiological/heat.svg";
import { ReactComponent as InjuriesSvg } from "../../assets/Svgs/Physiological/injuries.svg";
import { ReactComponent as CaloriesSvg } from "../../assets/Svgs/Physiological/calories.svg";
import { PopupForm } from "../PopupForm";
import { ColdnessCalc } from "./calculators/ColdnessCalc";
import { HeatCalc } from "./calculators/Heat load";
import usePermissions from "../../hooks/usePermissions";
import { LiquidCalc } from "./calculators/LiquidCalc";
import axios from "axios";
import { BASE_URL } from "../../api/constants";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";
import { Modal } from "../Modal";

export interface CalcProps {
  setShouldShow: React.Dispatch<React.SetStateAction<boolean>>;
  setToolTipMessage: React.Dispatch<React.SetStateAction<string>>;
  scrollFunction: () => void | undefined;
}

interface SizeProps {
  size: string;
}

interface Filter {
  isAdminUser: boolean | undefined;
}
const Wheel = styled.div<Filter>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    width: 2.5rem;
    height: 2.5rem;
    fill: #494949;
  }
  .heat {
    transform: translateY(-120%);
    display: flex;
    svg {
      fill: #eb7d00;
    }
  }

  .liquid {
    transform: translate(-115%, -30%);
    svg {
      fill: #07446d;
    }
  }

  .injuries {
    transform: translate(60%, 100%);
  }
  .calories {
    transform: translate(-60%, 100%);
  }

  .coldness {
    transform: translate(135%, -30%);
    /* svg {
      fill: ${(props) => props.isAdminUser && "#8cc9ce"};
    } */
  }

  .pinkCircle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.5rem;
    background-color: #f51b9b;
    border-radius: 100%;
  }
`;

const IconText = styled.div`
  position: absolute;
  padding: 1rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    width: 2.5rem;
    height: 2.5rem;
  }
  p {
    color: black !important;
    font-size: 12px !important;
    font-weight: 700 !important;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  max-width: 100vw;

  p {
    /* max-width: 80%; */
    font-size: 24px;
    font-weight: 500;
    justify-self: center;
    text-align: center;
    align-self: center;
    margin: 0.2rem;
    color: white;
  }
`;

const WheelStyle = styled.img<SizeProps>`
  /* margin-top: 1rem; */
  width: ${(props) => props.size + "rem"};
  height: ${(props) => props.size + "rem"};
  transform: rotate(180deg);
`;

const CenterWheel = styled.img<SizeProps>`
  /* margin-top: 1rem; */

  width: ${(props) => props.size + "rem"};
  height: ${(props) => props.size + "rem"};
`;

export const Physiological = () => {
  const [option, setOption] = useState("");
  const [open, setOpen] = useState(false);
  const { isAdminUser } = usePermissions();

  const [shouldShow, setShouldShow] = useState(false);
  const [toolTipMessage, setToolTipMessage] = useState("");

  const user = useRecoilValue(userInfo);
  const email = user.user.userPrincipalName;

  return (
    <DefaultContainer
      overflow={"hidden"}
      background={true}
      height={100}
      search={false}
    >
      <Modal shouldShow={shouldShow} setShouldShow={setShouldShow}>
        {toolTipMessage}
      </Modal>
      <PopupForm option={option} isOpen={open} setIsOpen={setOpen}>
        {isAdminUser && option === "coldness"
          ? (func) => (
              <ColdnessCalc
                setShouldShow={setShouldShow}
                setToolTipMessage={setToolTipMessage}
                scrollFunction={func}
              />
            )
          : option === "liquid"
          ? (func) => (
              <LiquidCalc
                setShouldShow={setShouldShow}
                setToolTipMessage={setToolTipMessage}
                scrollFunction={func}
              />
            )
          : option === "heat"
          ? (func) => (
              <HeatCalc
                setShouldShow={setShouldShow}
                setToolTipMessage={setToolTipMessage}
                scrollFunction={func}
              />
            )
          : () => <></>}
      </PopupForm>
      <Wrapper>
        <div>
          <p>טוב שהגעת!</p>
          <p>פיזיולוגי תחשב עבורך</p>
          <p>מדדים נבחרים</p>
        </div>
        <br />
        <Wheel isAdminUser={isAdminUser}>
          <IconText
            // onClick={() => {
            //   axios.post(
            //     `${BASE_URL}/areaLogs/addAreaLog`,
            //     { email, name: "עומס קור" },
            //     {
            //       withCredentials: true,
            //     }
            //   );
            //   isAdminUser && setOption("coldness");
            //   isAdminUser && setOpen(true);
            // }}
            className={"coldness"}
          >
            <ColdnessSvg />
            <p>{"עומס קור"}</p>
          </IconText>

          <IconText
            className={"heat"}
            onClick={() => {
              axios.post(
                `${BASE_URL}/areaLogs/addAreaLog`,
                { email, name: "עומס חום" },
                {
                  withCredentials: true,
                }
              );
              setOption("heat");
              setOpen(true);
            }}
          >
            {/* <img src={heatSvg} alt={"עומס חום"} /> */}
            <HeatSvg />
            <p>{"עומס חום"}</p>
          </IconText>

          <IconText className={"injuries"}>
            {/* <img src={injuriesSvg} alt={"טיפול בפגיעות"} /> */}
            <InjuriesSvg />
            <p>{"טיפול בפגיעות"}</p>
          </IconText>

          <IconText className={"calories"}>
            {/* <img src={caloriesSvg} alt={"הוצאה קלורית"} /> */}
            <CaloriesSvg />
            <p>{"הוצאה קלורית"}</p>
          </IconText>

          <IconText
            className={"liquid"}
            onClick={() => {
              axios.post(
                `${BASE_URL}/areaLogs/addAreaLog`,
                { email, name: "נוזלים ומלחים" },
                {
                  withCredentials: true,
                }
              );
              setOption("liquid");
              setOpen(true);
            }}
          >
            {/* <img
              // className={"options"}
              src={liquidSvg}
              alt={"נוזלים ומלחים"}
            /> */}
            <LiquidSvg />
            <p>{"נוזלים ומלחים"}</p>
          </IconText>

          <WheelStyle size={"20"} src={PhysiologicalSvg} />
          <div className="pinkCircle">
            <CenterWheel size={"3"} src={wheelCenter}></CenterWheel>
          </div>
        </Wheel>
      </Wrapper>
    </DefaultContainer>
  );
};
