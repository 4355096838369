import React, { useEffect } from "react";
import styled from "styled-components";

interface IProps {
  value: string;
  setValue: React.Dispatch<string>;
  text: string;
  isColumn?: boolean;
}

export const DateInput = ({ text, value, setValue, isColumn }: IProps) => {
  useEffect(() => {}, [value]);
  return (
    <Wrapper isColumn={isColumn!==undefined? isColumn : true}>
      <h4>{text}</h4>
      <input
        type="date"
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Wrapper>
  );
};

interface WrapperProps {
  isColumn: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: ${props=>props.isColumn? "column": "row"};
  margin-top: 1rem;

  h4 {
    margin: 0.2rem;
    margin-bottom: 0.5rem;
    transform: scale(0.9);
  }

  input[type="date"] {
    height: 5vh;
    width: 100%;
    outline: none;
    transform: scale(0.9);
    ::-webkit-calendar-picker-indicator {
      font-size: 1rem;
    }
    ::-webkit-clear-button {
      font-size: 5rem;
      height: 30px;
      position: relative;
    }
    ::-webkit-datetime-edit {
      padding: 1rem;
    }
  }
`;
