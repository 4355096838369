import styled, { keyframes } from "styled-components";
import { TreeNodeType } from "../TreeNode";
import { Dispatch, useEffect, useRef, useState } from "react";
import { LShape } from "../../assets/files/fileTreeSvgs";
import LineTo from "react-lineto";
import { FilePopup } from "./FilePopup";
import { ReactComponent as MP4 } from "../../assets/Svgs/mp4.svg";
import { ReactComponent as PDF } from "../../assets/Svgs/pdf.svg";
import { useFile } from "../../hooks/useFile";
import { RenamePopup } from "../RenamePopup";
import { SumPdf, aiState, isLoading, shouldShowState } from "../sumPdf";
import { log } from "console";
import { useSetRecoilState } from "recoil";
import { WarRenamePopup } from "../WarRenamePopup";
interface IProps {
  node: TreeNodeType;
  firstFile: boolean;
  refresh: boolean;
  setRefresh: Dispatch<React.SetStateAction<boolean>>;
  setFile?: Dispatch<React.SetStateAction<string>>;

  refreshParent?: Function;
}

export const WarFile: React.FC<IProps> = ({
  node,
  firstFile,
  refresh,
  setRefresh,
  refreshParent,
  setFile,
}) => {
  const { setTreeFile } = useFile();
  const textRef = useRef(null);
  const [nameFile, setNameFile] = useState<string>("");
  const [afterLoadingPopUP, setAfterLoadingPopUp] = useState(false);
  const [popUpRename, setPopUpRename] = useState<boolean>(false);
  const [popUpBol, setPopUpBol] = useState(false);
  const [loadingPopUp, setLoadingPopUp] = useState(false);
  const [toAnchor, setToAnchor] = useState("89.9% 90%");
  const [name, setName] = useState(node.displayName.replace(/\.[^/.]+$/, ""));
  const [showModal, setShowModal] = useState<boolean>(false);
  const [fileType, setFileType] = useState(node.name.split(".").slice(-1)[0]);
  const setAiAnswer = useSetRecoilState(aiState);
  const setShouldShow = useSetRecoilState(shouldShowState);
  const setIsLoading = useSetRecoilState(isLoading);

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  useEffect(() => {
    if (toAnchor == "89.99% 90%") setToAnchor("90% 90%");
    else setToAnchor("89.99% 90%");
    setRefresh(true);
  }, [refresh]);

  // useEffect(() => {
  //   if (showModal) {
  //     setTimeout(() => {
  //       toggleModal();
  //     }, 15000);
  //   }
  // }, [showModal]);
  return (
    <FileWrapper>
      <LShape id={node._id} />
      <FileNameWrapper
        onClick={() => {
          toggleModal();
          setTreeFile({
            filename: node.name,
            fileType: fileType,
            fileSrc: undefined,
          });
        }}
      >
        {fileType == "pdf" ? (
          <PDF className={`file`} />
        ) : fileType == "mp4" ? (
          <MP4 className={`file`} />
        ) : (
          <Rect />
        )}
        <div className="fileNameWrapper">
          <FileName
            isEllipsisActive={isEllipsisActive}
            e={textRef.current}
            ref={textRef}
          >
            {name}
          </FileName>
        </div>
      </FileNameWrapper>
      {firstFile && (
        <LineTo
          borderWidth={1.5}
          from={node.parent ? node.parent : ""}
          to={node._id}
          fromAnchor={"53% 50%"}
          toAnchor={toAnchor}
          borderColor={"#a1a1a1"}
          zIndex={-1}
        />
      )}

      {popUpRename && (
        <WarRenamePopup
          afterLoadingPopUP={afterLoadingPopUP}
          nameFile={nameFile} //שם הקובץ
          setNameFile={setNameFile}
          loadingPopUp={loadingPopUp} //הסתרה של הכן/לא
          setLoadingPopUp={setLoadingPopUp}
          setAfterLoadingPopUp={setAfterLoadingPopUp} //אחרי שהוא לחץ כן
          node={node}
          setRefresh={setRefresh} //מרנדר את התצוגה מחדש אחרי השינוי
          setPopUpRename={setPopUpRename} //הצגת הפופ אפ
          refreshParent={refreshParent}
        />
      )}
      <FilePopup
        setNameFile={setNameFile} //בשביל הפופאפ של השינוי שם
        setPopUpRename={setPopUpRename} //כדי להצגיד את הפופאפ של השינוי שם
        fileType={fileType} //זה סוג הקובץ (סרטון תמונה)
        showModal={showModal}
        setFile={setFile}
        refreshParent={refreshParent}
        setShowModal={setShowModal}
        setRefresh={setRefresh} //לא צריך
        node={node} //זה בעצם כל הקוביה של הקובץ של המעודפים
        onClickFunc={(question, url) => {
          if (url != "") {
            SumPdf(question, url).then((value) => {
              setAiAnswer(value);
              setShouldShow(true);
              setIsLoading(false);
            });
          } else console.log("no url");
        }}
      />
    </FileWrapper>
  );
};

const isEllipsisActive = (e: any) => {
  return e?.offsetWidth < e?.scrollWidth;
};

const FileWrapper = styled.div`
  height: 50px;
  overflow: visible;
  width: 100%;
`;
const myAnimation = keyframes`
    50%{
        transform: translateX(calc(140%));
    }
    59%{
        transform: translateX(calc(230%));
    }
    59.01%{
        transform: translateX(calc(-120%));
    }
    100% {
        transform: translateX(calc(0%));
    }
  }`;

interface FileNameProps {
  e: any;
  isEllipsisActive: Function;
}
const FileName = styled.div<FileNameProps>`
  margin: 0.8rem 0.5rem;
  bottom: 2rem;
  white-space: nowrap;

  overflow-x: scroll;

  margin-right: 0.5rem;
  ::-webkit-scrollbar {
    display: none;
  }

  /* -moz-animation: ${(props) =>
    props.isEllipsisActive(props.e) ? myAnimation : ""}
    10s linear infinite;
  -webkit-animation: ${(props) =>
    props.isEllipsisActive(props.e) ? myAnimation : ""}
    10s linear infinite;
  animation: ${(props) => (props.isEllipsisActive(props.e) ? myAnimation : "")}
    10s linear infinite;
  animation-delay: 3s; */
`;

const FileNameWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
  .fileNameWrapper {
    overflow: hidden;
  }
  .file {
    margin-top: 5px;
    border-radius: 8px;
    margin-right: 1.6rem !important;
    z-index: 1;
    width: 2rem;
    padding-left: 0.2rem;
    min-width: 2rem;
    height: 2rem;
  }
`;

const Rect = styled.div`
  margin-top: 5px;
  margin-right: 1.6rem !important;
  height: 1.5rem !important;
  width: 1.5rem !important;
  min-width: 1.5rem;

  z-index: 1;
  border-radius: 8px;
  background-color: #8fc8fa;
`;
