import axios from "axios";
import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { BASE_URL } from "../../api/constants";
import { DefaultContainer } from "../../containers/DefaultContainer";
import { CheckBox } from "../CheckBox";
import { DateInput } from "../DateInput";
import { List } from "../List";
import { Search } from "../Search";
import { BsSearch } from "react-icons/bs";
import { CARDS } from "../../constants";

interface IProps {
  text: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  number: number | undefined;
  color: string;
}
const spin = keyframes`
  0% {
     transform: rotate(0deg);
     }
  100% {
     transform: rotate(360deg);
      }
`;
export const DashBoardCard = ({ text, Icon, number, color }: IProps) => {
  return (
    <Container>
      <DivNumberText>
        <div id="number">{number === undefined ? <Spinner /> : number}</div>
        <div id="text">{text}</div>
      </DivNumberText>
      <DivAllIcon color={color}>
        <DivIcon color={color}></DivIcon>
        <Icon id={text} />
      </DivAllIcon>
    </Container>
  );
};

const DivIcon = styled.div<DivIconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-left: 0.5rem;
  background-color: ${(props) => props.color};
  opacity: 0.2;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  z-index: 1;
`;

const DivAllIcon = styled.div<DivIconProps>`
  /* position: absolute; */
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transform: scale(1.2); */
  /* margin-right: 7rem;
  margin-top: 0.6rem; */
  margin-left: 0.3rem;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    opacity: 1;
    z-index: 2;
    position: absolute;
    right: 0.5rem;
    /* margin-left: 0.5rem; */
    fill: ${(props) => props.color};
  }
`;

const Spinner = styled.div`
  /* position: relative;
  bottom: 2.4rem;
  right: 1.25rem; */
  /* top: 0.5rem; */
  border: 0.3rem solid #f3f3f3; /* Light grey */
  border-top: 0.3rem solid #8fc8fa; /* Blue */
  border-radius: 50%;
  width: 1.6rem;
  height: 1.6rem;
  /* z-index: -1; */
  animation: ${spin} 2s linear infinite;
  /* height: 8rem; */
`;

const Container = styled.div`
  /* border: 2px solid black; */
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 10vh;
  border-radius: 10px;
  border: 2px solid #f6f8f7;
  /* background-color: #feedfb; */
  box-shadow: 2px 5px 15px 0px #838683;
`;

const DivNumberText = styled.div`
  padding-right: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 60%;
  #number {
    font-weight: bold;
    font-size: 2.5rem;
    /* margin-top: 0.2rem; */
    color: #343d68;
    height: 8rem;
    display: flex;
    align-items: right;
    justify-content: flex-start;
    div {
      margin-top: 0.5rem;
    }
  }
  #text {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    color: #b2c3da;
    height: 100%;
    font-size: 0.8rem;
    width: 100%;
    font-weight: 600;
  }
`;
interface DivIconProps {
  color: string;
}
