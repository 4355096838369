import styled from "styled-components";
import { BASE_URL } from "../api/constants";
import bg from "../assets/login/bg.png";
import { ReactComponent as Scope } from "../assets/login/scope.svg";
import logo from "../assets/homepage/logo.png";
import { useState } from "react";
import { LoadingPage } from "./LoadingPage";

export const Login = () => {
  const [clickedLogin, setClickedLogin] = useState(false);
  return (
    <Wrapper showBg={!clickedLogin}>
      {!clickedLogin ? (
        <>
          <Header>
            <p>בטיחות בכף היד</p>
            <img src={logo} />
          </Header>
          <LoginComp>
            <LoginButton>
              <Link
                onClick={() => {
                  setClickedLogin(true);
                }}
                href={`${BASE_URL}/auth/microsoft`}
              >
                <p>כניסה למערכת</p>
              </Link>
            </LoginButton>
            <Scope />
          </LoginComp>
          <Footer>
            <p>עדיין לא יצרת משתמש MyIdf?</p>
            <a href="https://my.idf.il/">לחץ כאן</a>
          </Footer>
        </>
      ) : (
        <LoadingPage />
      )}
    </Wrapper>
  );
};

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 4.5rem;
  font-size: 0.9rem;
  font-weight: 600;
  overflow: hidden;
  img {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 8rem;
    width: 8rem;
  }
  a {
    /* text-decoration: none; */
    color: white;
  }
  color: white;
`;

const Header = styled.div`
  z-index: 2;
  width: 100vw;
  height: 30vh;
  padding-bottom: 1rem;
  background-color: #2e3841;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    color: white;
  }
  img {
    /* width: 16.5rem; */
    /* height: 14.3rem; */
    width: auto;
    height: auto;
    max-height: 60%;
  }
`;

const LoginComp = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 8rem;
  svg {
    position: relative;
    z-index: 1;
    width: calc(10rem * 1.9);
    height: calc(10rem * 1.9);
    position: absolute;
    fill: white;
    opacity: 0.1;
  }
`;

const Link = styled.a`
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  p {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: #2e3841;
  }
`;

const LoginButton = styled.div`
  position: relative;
  z-index: 2;
  background-color: #d8b214;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 30px 51px rgba(40, 43, 48, 0.7);
`;

interface IProps {
  showBg: boolean;
}

const Wrapper = styled.div<IProps>`
  margin: 0;
  height: 100vh;
  width: 100vw;
  background-image: ${(props) => (props.showBg ? `url(${bg})` : "none")};
  background-repeat: no-repeat;
  background-size: cover;
`;
