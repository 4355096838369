import React from "react";
import styled from "styled-components";
import { bool } from "prop-types";

import location from "../../assets/Svgs/location.svg";
import contentManager from "../../assets/Svgs/contentManager.svg";
import safteySchedule from "../../assets/Svgs/safteySchedule.svg";
import physioligical from "../../assets/Svgs/physioligical.svg";
import academyIcon from "../../assets/Svgs/academyIcon.svg";
import coneIcon from "../../assets/Svgs/coneIcon.svg";
import { ReactComponent as PersonalArea } from "../../assets/Svgs/personalArea.svg";
import { BASE_URL } from "../../api/constants";
import { useHistory } from "react-router";
import { FiLink } from "react-icons/fi";


interface IProps {
  open: Boolean;
}

export const Menu = ({ open }: IProps) => {
  const history = useHistory();
  return (
    <StyledMenu open={open}>
      <Options>
        <Option href="/files">- הידע בבטיחות</Option>
        <Option href="https://academy.digital.idf.il/courses?categories=81">
          - הכשרות וקורסים
        </Option>
        <Option onClick={() => history.push("/smartBasePage")}>
          - בסיס חכם
        </Option>
        <Section>
          <FiLink />
          <p>קישורים</p>
        </Section>
        <Option href="https://wiz.medone.idf.il/mu//icr/bot.aspx?l=18795">- תמרון בקליק - Bot</Option>
        <Option href="https://ims.gov.il/he/Fires">- שירות מטאורולוגי</Option>
        <Section>
          <PersonalArea />
          <p>האזור האישי</p>
        </Section>
        <Option href="/contact">- צור קשר</Option>
        <Option href={`${BASE_URL}/auth/logout`}>- התנתק</Option>
      </Options>
    </StyledMenu>
  );
};
Menu.propTypes = {
  open: bool.isRequired,
};

const Section = styled.div`
  display: flex;
  width: 100%;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  align-items: center;
  justify-self: right;
  align-self: right;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
  margin-right: 2rem;
  p {
    margin: 0;
  }
  svg {
    stroke: black;
    margin-left: 0.5rem;
    height: 2rem;
    width: 2rem;
  }
`;

const Options = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 8vh;
`;

const StyledMenu = styled.nav<IProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  height: 100vh;
  width: 65vw;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

const Option = styled.a`
  width: 90%;
  max-width: 100%;
  margin-right: 4rem;
  margin-bottom: 2rem;
  align-self: right;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  color: black;
  text-decoration: none;
  text-align: right;
`;
const SafetyOption = styled.a`
  width: 90%;
  max-width: 100%;
  margin-right: 4rem;
  margin-bottom: 2rem;
  align-self: right;
  font-size: 0.85rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  color: black;
  text-decoration: none;
  text-align: right;
`;
