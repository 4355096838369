import styled from "styled-components";
import { OptionsBar } from "./OptionsBar";
import { MdAdd, MdClose } from "react-icons/md";
import { FaBook, FaYoutube } from "react-icons/fa";
import { Dispatch, useCallback, useEffect, useRef, useState } from "react";
import { TreeNodeType } from "../TreeNode";
import { SumPdf, aiState, isLoading, shouldShowState } from "../sumPdf";
import { useSetRecoilState } from "recoil";
interface IProps {
  data: any;
  quote: string;
  trace: string[];
  searchTerm: string;
  setFile: Dispatch<React.SetStateAction<string>>;
}

const getHighlightedText = (text: string, highlight: string) => {
  // Split on highlight term and include term into parts, ignore case
  let parts = text?.split(new RegExp(`(${highlight})`, "gi"));
  if (parts.length === 1 && highlight.includes(" ")) {
    const highlight_words = highlight.split(" ");
    for (let i = 0; i < highlight_words.length; i++) {
      parts = text?.split(new RegExp(`(${highlight_words[i]})`, "gi"));
      if (parts.length > 1) {
        highlight = highlight_words[i];
        break;
      }
    }
  }

  return (
    <span>
      {parts?.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase() === highlight.toLowerCase()
              ? {
                  boxSizing: "border-box",
                  height: "35px",
                  width: "77px",
                  borderRadius: "3px",
                  // opacity: "0.5",
                  // paddingLeft: "3px",
                  // paddingRight: "3px",
                  backgroundColor: " #F9CE07",
                }
              : {}
          }
        >
          {part}
        </span>
      ))}
    </span>
  );
};

export const Tile = ({ data, searchTerm, quote, setFile, trace }: IProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const setAiAnswer = useSetRecoilState(aiState);
  const setShouldShow = useSetRecoilState(shouldShowState);
  const setIsLoading = useSetRecoilState(isLoading);
  return (
    <TileContainer>
      <div className="tileText">
        <TileName>
          {data.name.toLowerCase().substr(data.name.length - 4) === ".pdf" ? (
            <FaBook />
          ) : (
            <FaYoutube />
          )}
          {/* {data.name} */}
          {getHighlightedText(data.name, searchTerm)}
        </TileName>
        <RootFolder>
          {trace.map((item: string, index: number) => (
            <div key={index}>
              <div style={{ textDecoration: "underline" }}>{item}</div>
              {trace.length === index + 1 ? "" : "/"}
            </div>
          ))}
        </RootFolder>
        {quote && (
          <div className="quote">{getHighlightedText(quote, searchTerm)}</div>
        )}
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
        }}
      >
        <OptionButton onClick={() => setIsOpen(!isOpen)}>
          {isOpen ? <MdClose /> : <MdAdd />}
        </OptionButton>
        {isOpen && (
          <OptionsBar
            onClickFunc={(question, url) => {
              if (url != "") {
                SumPdf(question, url).then((value) => {
                  setAiAnswer(value);
                  setShouldShow(true);
                  setIsLoading(false);
                });
              } else console.log("no url");
            }}
            setFile={setFile}
            node={data}
          />
        )}
      </div>
    </TileContainer>
  );
};

const RootFolder = styled.div`
  display: flex;
  width: 100%;
  margin: 0.2rem 0 0 0;
  margin-right: 3.5rem;
  font-size: 0.8rem;
  color: #8f9497;
  div {
    display: flex;
    padding: 0 0 0 0.2rem;
  }
`;
const TileContainer = styled.div`
  background: white;
  margin: 0 0 1.5rem 0;
  padding-bottom: 1rem;
  .tileText {
    /* padding: 0.5rem 1rem 0.5rem 1rem; */
  }
  .quote {
    position: relative;
    font-size: 0.8rem;
    top: 0.5rem;
    right: 3.5rem;
    width: 80%;
    /* margin: 0.5rem 0 0 0; */
    /* margin-right: 4rem; */

    color: #8f9497;
  }
`;
const TileName = styled.div`
  padding-top: 1rem;
  font-weight: bolder;
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #2e3841;
  width: 100%;
  /* line-height: 51px; */
  svg {
    width: calc(5 / 3 * 1.5rem);
    min-width: calc(5 / 3 * 1.5rem);
    max-width: calc(5 / 3 * 1.5rem);
    height: 1.5rem;
    min-height: 1.5rem;
    max-height: 1.5rem;
    position: relative;
    right: 0rem;
    margin-left: 1rem;
    /* margin-right: -8px; */
  }
`;

const OptionButton = styled.div`
  width: min-content;
  background: white;
  border: none;
  color: white;
  padding: 0;
  height: 0.9rem;
  svg {
    height: 2rem;
    width: 2rem;
    background: #2e3841;
    border-radius: 0 5px 0 0;
  }
`;
