import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import menu from "../../assets/Svgs/menu.svg";
import settings from "../../assets/Svgs/settings.svg";
import user from "../../assets/Svgs/user.svg";
import { UserSelect } from "../userSelect";
import { BurgerMenu } from "../BurgerMenu";
import usePermissions from "../../hooks/usePermissions";
import { BASE_URL } from "../../api/constants";
import axios from "axios";
import { useRecoilValue } from "recoil";
import { userInfo } from "../../store/userInfo";

export const LeftSide = () => {
  const [profileOpen, setProfileOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const { isAdminUser, isSupportUser } = usePermissions();
  const [numberNotification, setNumberNotification] = useState(undefined);
  const userDetails = useRecoilValue(userInfo);
  const email = userDetails.user.userPrincipalName;
  useEffect(() => {
    if (isSupportUser) {
      axios
        .post(
          `${BASE_URL}/contactTickets/getOpenTicketsCount`,
          {},
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          if (result.data.success) {
            setNumberNotification(result.data.tickets);
          }
        });
    } else {
      axios
        .post(
          `${BASE_URL}/contactTickets/getMyClosedTickets`,
          { email },
          {
            withCredentials: true,
          }
        )
        .then((result) => {
          if (result.data.success) {
            setNumberNotification(result.data.tickets);
          }
        });
    }
  }, [isSupportUser]);

  return (
    <LeftSideWarper>
      {/* <LogOut /> */}
      {/* <LogoStyle src={settings} /> */}
      <DivUserWrapper>
        <LogoStyle
          src={user}
          onClick={() => {
            setProfileOpen((val) => !val);
          }}
        />
        {numberNotification !== undefined && numberNotification !== 0 && (
          <Notification profileOpen={profileOpen}>
            <div>{numberNotification}</div>
          </Notification>
        )}
      </DivUserWrapper>
      <UserSelect
        numberNotification={numberNotification}
        profileOpen={profileOpen}
      />

      <LogoStyle
        src={menu}
        onClick={() => {
          setMenuOpen(true);
        }}
      />
      {<BurgerMenu open={menuOpen} setMenuOpen={setMenuOpen} />}
    </LeftSideWarper>
  );
};

interface NotificationProps {
  profileOpen: boolean;
}

const DivUserWrapper = styled.div`
  position: relative;
`;

export const Notification = styled.div<NotificationProps>`
  position: absolute;
  /* z-index: 999; */
  /* overflow: visible; */
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
  border-radius: 50%;
  color: white;
  width: 1.3rem;
  height: 1.3rem;
  top: -0.5rem;
  left: -0.6rem;
  /* margin-left: 2.1rem;
  margin-top: -0.8rem; */
  opacity: ${(props) => (props.profileOpen ? "0" : "1")};
  transition: opacity 0.3s ease-in-out;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
    font-weight: bold;
    margin-top: -0.2rem;
  }
`;

const slideInFromLeft = keyframes`
  from {
    transform: translateX(-100%);
    visibility: hidden;
  }
  to {
    transform: translateX(0%);
    visibility: visible;
  }
  `;

const slideInFromRight = keyframes`
  from {
    transform: translateX(0);
    visibility: visible;
  }
  to {
    visibility: hidden;
    opacity: 0;
  }
`;

const LeftSideWarper = styled.div`
  width: 100%;
  margin-left: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  .slide-in-from-left {
    display: visible;
    animation: ${slideInFromLeft} 0.5s ease-in-out forwards;
  }
  .close {
    animation: ${slideInFromRight} 0.5s ease-in-out forwards;

    /* display: none; */
  }
`;

const LogoStyle = styled.img`
  width: 1.7rem;
  height: 1.7rem;
  padding-right: 1rem;
  -webkit-tap-highlight-color: transparent;
  :hover {
    cursor: pointer;
  }
`;
