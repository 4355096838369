import React from "react";
import styled from "styled-components";
import { NewToolTip } from "../NewToolTip";

export interface Option {
  onClick?: React.MouseEventHandler;
  Img: any;
  text: string;
  textt?: string;
  number?: number;
  isSelected: boolean;
  color?: string;
  explanation?: string;
  explanationExam?: string;
  disabled?: boolean;
  isExam?: boolean;
}
interface ImageOptionProps {
  onClick?: React.MouseEventHandler;
  Img: any;
  text: string;
  textt?: string;
  number?: number;
  isSelected: boolean;
  color?: string;
  explanation?: string;
  explanationExam?: string;
  disabled?: boolean;
  isExam?: boolean;
  setShouldShow: React.Dispatch<React.SetStateAction<boolean>>;
  setToolTipMessage: React.Dispatch<React.SetStateAction<string>>;
}

interface WrapperProps {
  isSelected: boolean;
  explanation?: string;
}
// const P = styled.p`
//   height: 10vh;
// `;

const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-grow: 1;
  background-color: ${(props) => (props.isSelected ? "#365ABA" : "none")};
  /* padding-bottom: 1.7rem; */
  padding: ${(props) => (props.explanation ? "0 1.9rem 0 1.9rem" : "0")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #b7c7d6;
  border-radius: 1rem;
  height: 6rem;
  min-width: 5rem;
  margin: 0 0.25rem;
  svg {
    width: 3rem;
    height: 3rem;
    z-index: -3;
  }
  p {
    max-width: 100%;
    height: 0.3rem;
    align-self: center;
    justify-self: center;
    text-align: center;
    font-size: 10px;
    font-weight: 600;
    margin-bottom: 0;
    color: ${(props) => (props.isSelected ? "white" : "black")};
  }
`;

const SvgTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ImageOption = ({
  text,
  textt,
  Img,
  number,
  isSelected,
  color,
  onClick,
  disabled,
  explanation,
  explanationExam,
  isExam,
  setShouldShow,
  setToolTipMessage,
}: ImageOptionProps) => {
  return (
    <Wrapper onClick={onClick} isSelected={isSelected} explanation={explanation}>
      <NewToolTip
        setShouldShow={setShouldShow}
        setToolTipMessage={setToolTipMessage}
        text={
          isExam
            ? explanationExam
              ? explanationExam
              : ""
            : explanation
            ? explanation
            : ""
        }
        top={0}
        right={130}
      >
        {number && <h5 onClick={onClick}>{number}</h5>}
        <SvgTextWrapper>
          <Img
            onClick={onClick}
            fill={isSelected ? "white" : disabled ? "gray" : color}
          />
          <p onClick={onClick}>{text}</p>
          <p className="r" onClick={onClick}>
            {textt}
          </p>
        </SvgTextWrapper>
      </NewToolTip>
    </Wrapper>
  );
};
