import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as userProfile } from "../../assets/Svgs/userProfile.svg";
import { DefaultContainer } from "../../containers/DefaultContainer";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { userInfo } from "../../store/userInfo";
import axios from "axios";
import { BASE_URL } from "../../api/constants";
import { FilePopup } from "../File/FilePopup";
import { TreeNodeType } from "../TreeNode";
import { VideoView } from "../VideoView";
import { FileView } from "../FileView";
import { useFile } from "../../hooks/useFile";
import { Favorites } from "../Favorites";
import { LoadingCircle } from "../LoadingCircle";

import { useHistory } from "react-router";
import { RenamePopup } from "../RenamePopup";
import { SumPdf, aiState, isLoading, shouldShowState } from "../sumPdf";

export const FavoritesUser = () => {
  const user = useRecoilValue(userInfo);
  const setAiAnswer = useSetRecoilState(aiState);
  const setShouldShow = useSetRecoilState(shouldShowState);
  const setIsLoading = useSetRecoilState(isLoading);
  const email = user.user.userPrincipalName;
  const [showModal, setShowModal] = useState(false);
  const [popUpRename, setPopUpRename] = useState<boolean>(false);
  const [loadingPopUp, setLoadingPopUp] = useState(false);
  const [afterLoadingPopUP, setAfterLoadingPopUp] = useState(false);
  const [nameFile, setNameFile] = useState<string>("");
  const [showPage, setShowPage] = useState(false);
  const [popUpBol, setPopUpBol] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const [node, setNode] = useState<TreeNodeType>();
  const [nodes, setNodes] = useState<TreeNodeType[]>([]);
  const [loading, setLoading] = useState(true);
  const { treeFile, resetFile } = useFile();
  const history = useHistory();

  useEffect(() => {
    if (treeFile) {
      resetFile();
    }
  }, []);

  useEffect(() => {
    axios
      .post(
        `${BASE_URL}/likes/favoriteUser`,
        { email: email },
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if (result?.data) {
          setNodes(result.data.nodes);
          setLoading(false);

          setRefresh(false);
        }
      });
  }, [refresh]);

  return (
    <>
      <DefaultContainer>
        <FilesWrapper file={treeFile.fileSrc}>
          {!loading ? (
            nodes.length == 0 ? (
              <ReturnIcon
                onClick={() => {
                  history.push("/");
                }}
              >
                {"חזור"}
              </ReturnIcon>
            ) : (
              <div></div>
            )
          ) : (
            <div></div>
          )}

          {
            <Header>
              <Title>{"מועדפים"}</Title>
            </Header>
          }

          {!loading ? (
            <>
              {nodes.length > 0 ? (
                <>
                  <DivScrole>
                    {nodes.map((item) => (
                      <>
                        <Favorites
                          key={item.displayName}
                          node={item}
                          setNode={setNode}
                          setShowModal={setShowModal}
                          setRefresh={setRefresh}
                        />
                      </>
                    ))}
                  </DivScrole>
                </>
              ) : (
                <NotFound>{"לא נמצאו מועדפים"}</NotFound>
              )}
            </>
          ) : (
            <LoadingCircle worde={"טוען..."} />
          )}

          {popUpRename && (
            <RenamePopup
              afterLoadingPopUP={afterLoadingPopUP}
              nameFile={nameFile} //שם הקובץ
              setNameFile={setNameFile}
              loadingPopUp={loadingPopUp} //הסתרה של הכן/לא
              setLoadingPopUp={setLoadingPopUp}
              setAfterLoadingPopUp={setAfterLoadingPopUp} //אחרי שהוא לחץ כן
              node={node}
              setRefresh={setRefresh} //מרנדר את התצוגה מחדש אחרי השינוי
              setPopUpRename={setPopUpRename} //הצגת הפופ אפ
            />

            // <DivPopUp>
            //   <DivTextPopUp>
            //     <label>הנא כתוב את השם החדש </label>
            //     <input
            //       type="text"
            //       value={nameFile}
            //       onChange={(e) => {
            //         setNameFile(e.target.value);
            //       }}
            //     ></input>
            //     {!loadingPopUp ? (
            //       <DivButtonPopUp>
            //         <button
            //           id="yes"
            //           onClick={() => {
            //             setLoadingPopUp(true);
            //             setAfterLoadingPopUp(false);
            //             axios
            //               .post(
            //                 `${BASE_URL}/tree/renameFile`,
            //                 { idFile: node?._id, name: nameFile },
            //                 {
            //                   withCredentials: true,
            //                 }
            //               )
            //               .then((result) => {
            //                 setAfterLoadingPopUp(true);
            //                 setRenderDeleteFavorites(true);
            //                 setTimeout(() => {
            //                   setLoadingPopUp(false);
            //                 }, 2000);
            //                 setTimeout(() => {
            //                   setPopUpRename(false);
            //                 }, 1000);
            //               });
            //           }}
            //         >
            //           אישור
            //         </button>
            //         <button
            //           id="no"
            //           onClick={() => {
            //             setPopUpRename(false);
            //           }}
            //         >
            //           ביטול
            //         </button>
            //       </DivButtonPopUp>
            //     ) : afterLoadingPopUP ? (
            //       <DivPositionLoading>
            //         השם שונה ל - {nameFile}
            //       </DivPositionLoading>
            //     ) : (
            //       <DivInmationLoading className="lds-ellipsis">
            //         <div></div>
            //         <div></div>
            //         <div></div>
            //         <div></div>
            //       </DivInmationLoading>
            //     )}
            //   </DivTextPopUp>
            // </DivPopUp>
          )}

          {showModal && node && (
            <FilePopup
              setNameFile={setNameFile} //בשביל הפופאפ של השינוי שם
              setPopUpRename={setPopUpRename} //כדי להצגיד את הפופאפ של השינוי שם
              fileType={""} //זה סוג הקובץ (סרטון תמונה)
              showModal={showModal}
              setShowModal={setShowModal}
              setRefresh={setPopUpBol} //לא צריך
              node={node} //זה בעצם כל הקוביה של הקובץ של המעודפים
              onClickFunc={(question, url) => {
                if (url != "") {
                  SumPdf(question, url).then((value) => {
                    setAiAnswer(value);
                    setShouldShow(true);
                    setIsLoading(false);
                  });
                } else console.log("no url");
              }}
            />
          )}
        </FilesWrapper>
        {treeFile.fileSrc && (
          <FileViewWrapper>
            {node?.name.split(".").slice(-1)[0]?.toLowerCase() == "mp4" ? (
              <VideoView type={"video/mp4"} source={treeFile.fileSrc} />
            ) : (
              <FileView file={treeFile.fileSrc} /> //src
            )}
          </FileViewWrapper>
        )}
      </DefaultContainer>
    </>
  );
};

const ellipsis1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }

`;
const ellipsis3 = keyframes` 
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const ellipsis2 = keyframes` 
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const DivInmationLoading = styled.div`
  &&.lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 20%;
  }
  &&.lds-ellipsis div {
    position: absolute;
    top: 18px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #8a8989;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  &&.lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: ${ellipsis1} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: ${ellipsis2} 0.6s infinite;
  }
  &&.lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: ${ellipsis3} 0.6s infinite;
  }
`;

const DivPositionLoading = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  color: green;
  height: 20%;
`;

const DivButtonPopUp = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  height: 20%;
  #yes {
    width: 5rem;
    height: 3rem;
    color: black;
    font-size: 1rem;
    font-weight: bold;
    outline: none;
    background: white;
    padding: 0.8rem;
    border-radius: 5px;
    border: none;
    border: 2px solid green;
  }
  #no {
    width: 5rem;
    height: 3rem;
    color: black;
    font-size: 1rem;
    font-weight: bold;
    outline: none;
    background: white;
    padding: 0.8rem;
    border-radius: 5px;
    border: none;
    border: 2px solid red;
  }
`;

const DivTextPopUp = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 17rem;
  height: 12rem;
  background-color: white;
  padding: 1rem;
  border-radius: 10px;
  border: 3px solid #d1d0d0;
  box-shadow: 5px 25px 25px 10px #8a8989;
  label {
    font-size: 1.5rem;
    /* border-bottom: 1px double black; */
  }
  input {
    /* border-radius: 25px; */
    font-size: 1rem;
    outline: none;
    border: none;
    border-bottom: 1px solid black;
  }
`;

const DivPopUp = styled.div`
  position: absolute;
  top: 20%;
  right: 11%;
  z-index: 2;
`;

const ReturnIcon = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  text-align: center;
  margin-top: 2rem;
  margin-right: 1rem;
  width: 3rem;
  font-size: 1.3rem;
  border-bottom: 1.6px solid #cccbcb;
`;

const NotFound = styled.div`
  display: flex;
  width: 15rem;
  margin: auto;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
  font-size: 1.5rem;
  border-bottom: 1.6px solid #cccbcb;
  letter-spacing: 2px;
`;

const PopupDeleteFolder = styled.div`
  background: green;
  width: 10rem;
  margin: auto;
  padding: 0.3rem;
  text-align: center;
  border-radius: 25px;
  color: white;
`;

const IconeClose = styled.div`
  display: flex;
  width: 15rem;
  margin: auto;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  margin-top: 6rem;
  font-size: 1.5rem;
  border-bottom: 1.6px solid #cccbcb;
  letter-spacing: 2px;
`;

const DivScrole = styled.div`
  height: 70vh;
  max-height: 70vh;
  overflow: scroll;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 1rem;
`;

const FilesWrapper = styled.div<{ file?: string }>`
  background-color: white;
  margin-top: -1rem;
  display: ${(props) => (props?.file ? "none" : "")};
`;

const FileViewWrapper = styled.div`
  position: absolute;
  display: flex;
  background-color: white;
  width: 100vw;

  justify-content: center;
  z-index: 2;
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d4d4d;
  font-weight: bold;
`;
const Content = styled.div`
  background-color: white;
  display: flex;
  flex-direction: row;
  padding-top: 1.3rem;
  padding-bottom: 1.3rem;
  border-bottom: 1.6px solid #cccbcb;
  width: 78%;
  margin: auto;
  #icone {
    width: 3rem;
    font-size: 1.5rem;
    /* border-bottom: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  #name {
    display: flex;
    width: 13.5rem;
    align-items: center;
    font-size: 1.1rem;
  }
  #star {
    width: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0.5rem;
  }
`;
