import axios from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { BASE_URL } from "../../api/constants";
import { DefaultContainer } from "../../containers/DefaultContainer";
import { newSearch, searchWord } from "../../store/search";
import { userInfo } from "../../store/userInfo";
import { FileView } from "../FileView";
import { TreeNodeType } from "../TreeNode";

import { Tile } from "./Tile";
import { AiModal } from "../File/FilePopup/AiModal";
import { aiState, shouldShowState } from "../sumPdf";

export const Search = () => {
  const user = useRecoilValue(userInfo);
  const searchTerm = useRecoilValue(searchWord);
  const canSearch = useRecoilValue(newSearch);
  const [isSearched, setIsSearched] = useState(false);
  const [canFetch, setCanFetch] = useState(false);
  const [file, setFile] = useState<string>("");
  const aiAnswer = useRecoilValue(aiState);
  const shouldShow = useRecoilValue(shouldShowState);

  let fatchQueryResult = async ({ pageParam = 1 }) => {
    const { data } = await axios.get(
      `${BASE_URL}/search?word=${searchTerm}&page=${pageParam}&limit=${10}`,
      {
        withCredentials: true,
      }
    );
    if (data && (data == "אין תוצאות" || data == "Invalid search word")) {
      data.pages = [{ results: [], quote: [], traces: [] }];
      setCanFetch(false);
    } else if (data) {
      setCanFetch(true);
    }
    setIsSearched(true);
    return data;
  };

  const {
    isFetching,
    refetch,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    data,
    fetchNextPage,
  } = useInfiniteQuery(
    "items",
    fatchQueryResult,

    {
      enabled: canSearch,
      getNextPageParam: (lastPage, pages) => {
        if (pages[0]?.results?.length > 0) {
          return lastPage?.next?.page;
        }
        return [];
      },
      getPreviousPageParam: (firstPage, allPages) => firstPage.prevCursor,
    }
  );

  useEffect(() => {
    refetch();
  }, [canSearch]);

  const elementRef = useRef<any>(null);

  const lastTile = useCallback(
    (node) => {
      if (isLoading) return;
      if (elementRef.current) elementRef.current.disconnect();

      elementRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          fetchNextPage();
        }
      });

      if (node) elementRef?.current?.observe(node);
    },
    [isLoading, hasNextPage]
  );

  return (
    <DefaultContainer background>
      <AiModal shouldShow={shouldShow}>{aiAnswer}</AiModal>
      {/* {!isFetching && data?.pages[0]?.results.length === 0 && <div>אין תוצאות</div>} */}
      {file && (
        <FileViewWrapper>
          <FileView setFile={setFile} file={file} />
        </FileViewWrapper>
      )}
      {isSearched &&
      (data == undefined || data?.pages[0]?.results?.length == 0) ? (
        <ResultsWrapper file={file}>
          <p>אין תוצאות</p>
        </ResultsWrapper>
      ) : (
        <ResultsWrapper file={file}>
          <div className="abc" style={{ overflow: "hidden" }}>
            {/* <div>{`data: ${JSON.stringify(
              data
            )}, isSearched: ${isSearched}`}</div> */}
            {data?.pages.map(
              (item: { results: []; quote: []; traces: [] }, i) => (
                <div key={i}>
                  {item?.results?.map((tile: TreeNodeType, index) => {
                    if (!tile?.children) {
                      return (
                        <div key={index}>
                          {item?.results.length === index + 1 ? (
                            <div ref={lastTile} key={tile._id}>
                              <Tile
                                data={tile}
                                setFile={setFile}
                                quote={item.quote[index]}
                                trace={item.traces[index]}
                                searchTerm={searchTerm}
                              />
                            </div>
                          ) : (
                            <div key={tile._id}>
                              <Tile
                                data={tile}
                                setFile={setFile}
                                quote={item.quote[index]}
                                trace={item.traces[index]}
                                searchTerm={searchTerm}
                              />
                            </div>
                          )}
                        </div>
                      );
                    } else {
                      return <></>;
                    }
                  })}
                </div>
              )
            )}
          </div>
          {isFetching && <div className="loader"></div>}
        </ResultsWrapper>
      )}
    </DefaultContainer>
  );
};

const FileViewWrapper = styled.div`
  position: absolute;
  display: flex;
  background-color: white;
  width: 100vw;

  justify-content: center;
  z-index: 2;
  margin-top: -1rem;
`;

const ResultsWrapper = styled.div<{ file: string }>`
  display: ${(props) => (props.file ? "none" : "")};
`;
